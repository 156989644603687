import Vue from 'vue'
import Vuex from 'vuex'
import webapp from 'rnjs-webapp'
import login from './login'
import setting from './setting'
import home from './home'
import guaranty from './guaranty'
import team from './team'
import information from './information'
import mall from './mall'
import dadao from './dadao'
import applicationInxi from './special/applicationInxi'

Vue.use(Vuex)

export default new Vuex.Store({
  // 模块
  modules: {
    ...webapp.store,
    login,
    setting,
    home,
    guaranty,
    team,
    information,
    mall,
    dadao,
    applicationInxi
  }
})
